import * as Sentry from "@sentry/vue";
import random from "lodash/random";
import difference from "lodash/difference";
import bridge from "@vkontakte/vk-bridge";
import Bottleneck from "bottleneck";
import { v4 as uuidv4 } from "uuid";

import { APP_ID, VK_API_VERSION, ERROR_MESSAGES } from "../../constants.js";
import { getQueryVariable } from "../url-utils.js";
import { useDefaultStore } from "../../store.js";

const REQUEST_RETRIAL_ATTEMPTS = 3;
const RETRYABLE_ERRORS = new Set([
    "Rate limit reached, client_error, 1, 29",
    "Too many requests per second, client_error, 1, 6",
    "Network error, client_error, 1",
]);

const limiter = new Bottleneck({
    maxConcurrent: 1,
    reservoir: 3, // initial value
    reservoirRefreshAmount: 3,
    reservoirRefreshInterval: import.meta.env.PROD ? 1250 : 0, // must be divisible by 250
});
const addRequest = limiter.wrap(callAPI);

let authToken;
const requiredScopes = ["wall", "groups"];

export async function initVK() {
    const defaultStore = useDefaultStore();

    if (["test", "development"].includes(import.meta.env.MODE)) {
        defaultStore.setVkInitialized();
        return;
    }

    const appId = Number.parseInt(getQueryVariable("vk_app_id"), 10) || APP_ID;
    const method = "VKWebAppGetAuthToken";
    const options = {
        app_id: appId,
        scope: requiredScopes.join(","),
    };

    try {
        const result = await bridge.send(method, options);
        authToken = result.access_token;
        const grantedScopes = result.scope.split(",");
        const missingScopes = difference(requiredScopes, grantedScopes);

        if (missingScopes.length > 0) {
            defaultStore.setErrorMessage(
                ERROR_MESSAGES.missingRequiredPermissions
            );
            defaultStore.changePage("Error");
        } else {
            defaultStore.setVkInitialized();
        }
    } catch (error) {
        const errorMessage = createApiErrorMessage(error);
        captureResponseError(error, method, options);
        defaultStore.setErrorMessage(
            errorMessage === "User denied, client_error, 4"
                ? ERROR_MESSAGES.missingRequiredPermissions
                : ERROR_MESSAGES.cantInitVK
        );
        defaultStore.changePage("Error");
    }
}

function createApiErrorMessage(error) {
    const { error_type, error_data = {} } = error;
    const { error_code, error_reason } = error_data;

    const errorMessage = [
        error_reason?.error_msg || error_reason,
        error_type,
        error_code,
        error_reason?.error_code,
    ]
        .filter(Boolean)
        .join(", ")
        .trim();

    return errorMessage;
}

function captureResponseError(error, method, options = {}) {
    if (error instanceof Error) {
        Sentry.captureException(error);
    } else {
        const errorMessage = createApiErrorMessage(error);

        Sentry.captureMessage(errorMessage, {
            tags: { method },
            extra: {
                request: { method, options },
                response: error,
            },
        });
    }
}

async function callAPI(method, parameters) {
    return bridge.send("VKWebAppCallAPIMethod", {
        method,
        request_id: uuidv4(),
        params: {
            ...parameters,
            v: VK_API_VERSION,
            access_token: authToken,
        },
    });
}

export async function fetchAPI(method, options, attempt = 1) {
    if (["test", "development"].includes(import.meta.env.MODE)) {
        return fakeApi(method, options);
    }

    try {
        const result = await addRequest(method, options);
        // Do not return directly as that will break error handling
        return result;
    } catch (error) {
        if (error instanceof Error) {
            Sentry.captureException(error);
        } else {
            const errorMessage = createApiErrorMessage(error);
            if (
                attempt < REQUEST_RETRIAL_ATTEMPTS &&
                RETRYABLE_ERRORS.has(errorMessage)
            ) {
                return fetchAPI(method, options, attempt + 1);
            }
            captureResponseError(error, method, options);
        }
    }
}

async function fakeApi(method, options) {
    const { default: faker } = await import("faker/locale/ru");

    const userId = faker.datatype.number();
    switch (method) {
        case "likes.getList":
            return {
                response: {
                    count: 24,
                    items: [1_155_870, 1, 4_352_808],
                },
            };
        case "groups.isMember":
            return {
                response: {
                    member: random(0, 1),
                    can_invite: 0,
                },
            };
        case "users.get":
            return {
                response: [
                    {
                        id: userId,
                        first_name: faker.name.firstName(),
                        last_name: faker.name.lastName(),
                        can_access_closed: true,
                        is_closed: true,
                        photo_50: `https://picsum.photos/50/50?random=${faker.datatype.number()}`,
                        domain:
                            Math.random() > 0.5
                                ? faker.internet.userName()
                                : `id${userId}`,
                    },
                ],
            };
        case "wall.getReposts":
            return {
                response: {
                    items: [
                        {
                            id: 322,
                            from_id: 191_888_536,
                            to_id: 191_888_536,
                            date: 1_581_726_730,
                            post_type: "post",
                            text: "",
                            copy_history: [
                                {
                                    id: 152,
                                    owner_id: -142_399_012,
                                    from_id: -142_399_012,
                                    date: 1_554_837_009,
                                    post_type: "post",
                                    text: 'Небольшое обновление.\nСегодня я был вынужден отключить сообщения сообщества.\nТам и раньше около 95% сообщений были не по теме: то люди думали что там бот и писали вещи вроде "от 1 до 100", то просто кидали ссылку без каких-либо слов, то присылали совершенно какие-то дикие вещи вроде набора букв или целого абзаца текста про лиственные леса (не шутка). И среди всего этого бардака терялись действительно важные сообщения, на многие из которых я так и не ответил.\n\nСейчас же всё достигло каких-то неадекватных масштабов. В день я получаю сотни сообщений про "ВК коины". 😄\n\nВ общем, общение через сообщения сообщества потеряло всякий смысл. Оно лишь отнимает время и нервы, отвлекает от улучшения приложения.\n\nПри этом, я совершенно не хочу терять контакт с пользователями. Если у кого-либо из вас возникнут проблемы с работоспособностью приложения, либо появится хорошая идея по улучшению приложения - прошу писать мне личные сообщения (ссылка на мою страницу есть в подписи данного поста).',
                                    signer_id: 17_300_765,
                                    post_source: {
                                        type: "vk",
                                    },
                                },
                            ],
                            comments: {
                                count: 0,
                            },
                            likes: {
                                can_like: 0,
                                count: 0,
                                user_likes: 0,
                            },
                            reposts: {
                                count: 0,
                            },
                            views: {
                                count: 6,
                            },
                            is_favorite: false,
                            donut: {
                                is_donut: false,
                            },
                            short_text_rate: 0.8,
                        },
                    ],
                    profiles: [
                        {
                            id: 17_300_765,
                            first_name: "Тимофей",
                            last_name: "Шилов",
                            can_access_closed: true,
                            is_closed: true,
                            sex: 2,
                            screen_name: "tim.shilov",
                            photo_50:
                                "https://sun1.informsvyaz.userapi.com/s/v1/if1/ji_eKCBfsI3D_9byADoAbVpIJ4qWwPNL_cgpyda5Y9wpHyr3pxwGQTX6YcPAxxvabt0xRM-0.jpg?size=50x50&quality=96&crop=343,0,1184,1184&ava=1",
                            photo_100:
                                "https://sun1.informsvyaz.userapi.com/s/v1/if1/e49-ldIRxUTGNdY_pzdq62vwUnwzImJM3eZBvGqcKBNC05H4MyD8kGBHkH_L7Aruu955W9qm.jpg?size=100x100&quality=96&crop=343,0,1184,1184&ava=1",
                            online_info: {
                                visible: true,
                                last_seen: 1_640_769_990,
                                is_online: true,
                                is_mobile: false,
                            },
                            online: 1,
                        },
                        {
                            id: 191_888_536,
                            first_name: "Алексей",
                            last_name: "Сопин",
                            can_access_closed: true,
                            is_closed: false,
                            sex: 2,
                            screen_name: "sopin87",
                            photo_50:
                                "https://sun2.informsvyaz.userapi.com/s/v1/ig2/zLkTtvrxtF3ba-hI_3IIf0-4iFzlTbDMkpGd--YpP2B6VjVX49K-LlK7c0Jhq9NoHsVJM9tCm0cS_LUmRXY10A3a.jpg?size=50x50&quality=96&crop=121,121,972,972&ava=1",
                            photo_100:
                                "https://sun2.informsvyaz.userapi.com/s/v1/ig2/XlzLbR1uqICQEMFI-aLutRYb1cbEHlPYdXi8wk9735ignA0Xpyu1gE8xeiMpGpaj7qe4PDGs68MULX2tlip1wEsN.jpg?size=100x100&quality=96&crop=121,121,972,972&ava=1",
                            online_info: {
                                visible: true,
                                last_seen: 1_640_768_944,
                                is_online: false,
                                app_id: 2_274_003,
                                is_mobile: true,
                            },
                            online: 0,
                        },
                    ],
                    groups: [
                        {
                            id: 142_399_012,
                            name: 'Официальная группа приложения "Рандомайзер"',
                            screen_name: "randomizer_app",
                            is_closed: 0,
                            type: "page",
                            photo_50:
                                "https://sun2.informsvyaz.userapi.com/s/v1/if1/D4kd1GsTOoBfPYsuQd78gMqNtXR3FWOReye_U_2YoU-3EV4z2WPIYsnJnv7g1E2fAU6yUSjB.jpg?size=50x50&quality=96&crop=51,51,410,410&ava=1",
                            photo_100:
                                "https://sun2.informsvyaz.userapi.com/s/v1/if1/Cu0gbB98VL_jv_paL0wSV6S4j710fKXuj3HdzRWJxI6Vfk9qdhT8x069sww792d2zeb2KIr1.jpg?size=100x100&quality=96&crop=51,51,410,410&ava=1",
                            photo_200:
                                "https://sun2.informsvyaz.userapi.com/s/v1/if1/nYhlD1QaTOp_gW19KvNJGtF6MDjfnuZaIlf0Bcf4QUE_T67jMTQS1TsBOEPptz8FSwJgB_gg.jpg?size=200x200&quality=96&crop=51,51,410,410&ava=1",
                        },
                    ],
                },
            };
        default:
            console.error("Unknown API method", method, options);
    }
}

export function resizeWindowToFitAll() {
    const MAX_HEIGHT = 4050;
    const EXTRA_BLANK_SPACE_HEIGHT = 75;

    const height = Math.min(
        document.body.offsetHeight + EXTRA_BLANK_SPACE_HEIGHT,
        MAX_HEIGHT
    );

    if (bridge.supports("VKWebAppResizeWindow")) {
        bridge.send("VKWebAppResizeWindow", { width: 800, height });
    }
}
