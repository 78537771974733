export function parseUrl(url = "") {
    try {
        let parse;
        if (url.includes("w=")) {
            parse = url.split("w=");
            url = parse[1] || "";
        }
        parse = url.split("wall");
        url = parse[1] || "";
        parse = url.split("&");

        url = parse[0] || "";
        parse = url.split("%");
        url = parse[0] || "";
        parse = url.split("_");

        const post = {
            owner: Number.parseInt(parse[0], 10),
            post_id: Number.parseInt(parse[1], 10),
        };
        return post;
    } catch {
        return false;
    }
}

export function getQueryVariable(variable) {
    const query = window.location.search.slice(1);
    const variables = query.split("&");
    for (const variable_ of variables) {
        const pair = variable_.split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return false;
}
