import { defineStore } from "pinia";
import { resizeWindowToFitAll } from "./utils/vk/index.js";

export const useDefaultStore = defineStore("default", {
    state: () => ({
        page: "Main",
        errorMessage: "Недостаточно участников, чтобы разыграть все призы!",
        vkInitialized: false,
    }),
    actions: {
        changePage(newPage) {
            this.page = newPage;
            setTimeout(() => {
                // part of the mdl-lite, refreshes elements
                window.componentHandler?.upgradeDom?.();
                resizeWindowToFitAll();
            }, 0);
        },
        setErrorMessage(message) {
            this.errorMessage = message;
        },
        setVkInitialized() {
            this.vkInitialized = true;
        },
    },
});
