export const APP_ID = "4938347";
export const APP_URL = `https://vk.com/app${APP_ID}`;
export const VK_API_VERSION = "5.131";

export const ERROR_MESSAGES = {
    cantGetReposts: `Не удалось получить количество лайков/репостов.<br><br>
        Обратите внимание, что согласно ограничениям ВКонтакте, данные о лайках/репостах доступны только для записей,
        созданных текущим пользователем или сообществом, в котором он является администратором.`,
    notEnoughParticipants: `Не хватает репостов, чтобы разыграть все призы.<br>
        Если количество репостов больше количества призов, возможно, некоторые репосты сделаны сообществами
        или заблокированными пользователями, которые не могут принимать участие в розыгрышах.`,
    cantInitVK:
        "Инициализация VK API не удалась. Попробуйте перезагрузить страницу.",
    missingRequiredPermissions:
        "Отсутствуют необходимые права доступа. Чтобы получить список лайков/репостов приложению необходимы доступ к записям на вашей стене и доступ к сообществам. Пожалуйста дайте приложению необходимые права доступа иначе оно не сможет работать!",
    unknown: "Неизвестная ошибка. Попробуйте перезагрузить страницу.",
};
