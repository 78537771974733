<template>
    <div class="wrapper">
        <div v-if="showAd" id="vk_ads_136124"></div>
    </div>
</template>

<script>
import random from "lodash/random";

const AD_SHOWING_THRESHOLD = 50;

export default {
    name: "FooterAd",
    data: () => {
        const showAd = random(0, 100) > AD_SHOWING_THRESHOLD;

        return {
            showAd,
        };
    },
    mounted: async function () {
        if (!this.showAd) return;

        window.VK?.Widgets?.Ads?.(
            "vk_ads_136124",
            {},
            {
                ad_unit_id: 136_124,
                ad_unit_hash: "fbc3cdaeba177e7d03e139ebcc67e4e8",
            }
        );
    },
};
</script>

<style scoped>
.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
