<template>
    <div id="prizes">
        <p v-if="prizes.length === 0" class="formTitle subheader">
            Список призов пуст!
            <b>Не забудьте нажать на кнопку &quot;+&quot;!</b>
        </p>
        <p
            v-if="prizes.length > 0"
            id="prizesLabel"
            class="formTitle subheader"
        >
            Список призов (всего: {{ totalPrizeCount }} шт.):
        </p>
        <div v-for="prize in prizes" :key="prize.id" class="prizeItem">
            {{ prize.title }} - {{ prize.value }} шт.
            <a class="deletePrize" @click="deletePrize(prize.id)">&nbsp;</a>
        </div>
    </div>
</template>

<script>
export default {
    name: "PrizesList",
    props: {
        prizes: { type: Array, default: () => [] },
        deletePrize: { type: Function },
    },
    emits: ["deletePrize"],
    computed: {
        totalPrizeCount() {
            let result = 0;
            for (const prize of this.prizes) {
                result += prize.value;
            }
            return result;
        },
    },
};
</script>

<style lang="scss" scoped>
.prizeItem {
    color: white;
    background-color: #db4d54;
    display: inline-block;
    padding: 5px;
    margin: 4px;
    border-radius: 4px;
}

.deletePrize {
    cursor: pointer;
    background-image: url("../assets/images/iconRemove.png");
    background-repeat: no-repeat;
    display: inline-block;
    width: 16px;
    height: 20px;
    background-position: center;
    margin-left: 5px;
}
</style>
