<script setup>
// https://v3.vuejs.org/api/sfc-script-setup.html#basic-syntax

import AppHeader from "./components/AppHeader.vue";
import FooterAd from "./components/FooterAd.vue";
import DevelopmentModePanel from "./components/DevelopmentModePanel.vue";
import PrizesList from "./components/PrizesList.vue";

const { PROD: isProduction } = import.meta.env;

if (!isProduction && window.location.search === "") {
    window.location.search = "?vk_user_id=123&access_token=321&secret=real";
}
</script>

<template>
    <DevelopmentModePanel v-if="!isProduction"></DevelopmentModePanel>
    <div id="content-wrapper">
        <div id="content" class="paper">
            <AppHeader :page="defaultStore.page" :get-winners="getWinners" />

            <section
                v-if="defaultStore.page === 'Main'"
                id="pageMain"
                class="flex-column"
            >
                <div
                    id="postLink-container"
                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label"
                >
                    <input
                        id="postLink"
                        v-model="postLink"
                        type="text"
                        class="mdl-textfield__input"
                    />
                    <label class="mdl-textfield__label" for="postLink"
                        >Введите ссылку на пост с розыгрышем...</label
                    >
                    <span id="postLink-error" class="mdl-textfield__error"
                        >Ссылка должна иметь следующий формат:
                        https://vk.com/<strong>wall-142399012_3</strong>.
                        <a
                            href="https://vk.com/randomizer_app?w=page-142399012_54144581"
                            target="_blank"
                            >Где взять правильную ссылку?</a
                        >
                    </span>
                </div>

                <div class="flex-row">
                    <div
                        id="prizeTitle-container"
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label flex-3"
                    >
                        <input
                            id="prizeTitle"
                            v-model="prizeTitle"
                            type="text"
                            class="mdl-textfield__input"
                        />
                        <label class="mdl-textfield__label" for="prizeTitle">
                            Наименование приза...
                        </label>
                        <span
                            id="prizeTitle-error"
                            class="mdl-textfield__error"
                        >
                            Обязательное поле!
                        </span>
                    </div>

                    <div
                        id="prizeValue-container"
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label flex-1"
                        style="margin-left: 10px"
                    >
                        <input
                            id="prizeValue"
                            v-model="prizeValue"
                            type="number"
                            min="1"
                            max="9999"
                            class="mdl-textfield__input"
                        />
                        <label class="mdl-textfield__label" for="prizeValue"
                            >Количество призов...</label
                        >
                        <span
                            id="prizeValue-error"
                            class="mdl-textfield__error"
                        >
                            Количество призов должно быть числом!
                        </span>
                    </div>

                    <button
                        class="mdl-button mdl-js-button mdl-button--icon btn-add-prize"
                        @click="addPrize()"
                    >
                        <i class="material-icons">add_circle</i>
                    </button>
                </div>

                <p class="subheader">Выбирать на основе:</p>

                <div class="contest-source">
                    <label
                        class="mdl-radio mdl-js-radio mdl-js-ripple-effect"
                        for="option-1"
                    >
                        <input
                            id="option-1"
                            type="radio"
                            class="mdl-radio__button"
                            name="contestType"
                            value="copies"
                            checked
                        />
                        <span class="mdl-radio__label">Репостов</span>
                    </label>

                    <label
                        class="mdl-radio mdl-js-radio mdl-js-ripple-effect"
                        for="option-2"
                    >
                        <input
                            id="option-2"
                            type="radio"
                            class="mdl-radio__button"
                            name="contestType"
                            value="likes"
                        />
                        <span class="mdl-radio__label">Лайков</span>
                    </label>

                    <label
                        v-if="postLink.includes('wall-')"
                        id="chkSubscription-label"
                        class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect"
                        for="chkSubscription"
                    >
                        <input
                            id="chkSubscription"
                            v-model="contest.checkSubscription"
                            type="checkbox"
                            class="mdl-checkbox__input"
                        />
                        <span class="mdl-checkbox__label"
                            >Пользователь должен быть подписан на
                            сообщество</span
                        >
                    </label>
                </div>

                <PrizesList :prizes="prizes" :delete-prize="deletePrize" />
                <div class="buttons">
                    <button
                        id="btnGetWinners"
                        class="mdl-button mdl-js-button mdl-js-ripple-effect colored bg-1"
                        @click="getWinners()"
                    >
                        Определить победителя
                    </button>
                </div>
            </section>

            <section v-if="defaultStore.page === 'Loading'" id="pageLoading">
                <div class="loading-image">
                    <img src="./assets/images/gift.gif" />
                </div>

                <div class="preload-bar style-scope preload-overlay">
                    <div
                        class="preload-progress style-scope preload-overlay"
                    ></div>
                </div>

                <p id="loadingText">{{ loadingText }}</p>
            </section>

            <section v-if="defaultStore.page === 'Error'" id="pageError">
                <p id="errorMessage" v-html="defaultStore.errorMessage"></p>
                <div class="buttons">
                    <button
                        class="mdl-button mdl-js-button mdl-js-ripple-effect colored bg-1"
                        @click="changePage('Main')"
                    >
                        Назад
                    </button>
                </div>
            </section>

            <!-- TODO[2022-03-01]: Remove this section -->
            <section
                v-if="defaultStore.page === 'DeprecatedSaveScreen'"
                id="pageDeprecatedSaveScreen"
            >
                <p>
                    Функция сохранения изображения была удалена из приложения по
                    причине плохой стабильности и плохой поддержки браузерами и
                    мобильными клиентами (поддержка которых в разработке).
                </p>
                <p>
                    <strong>
                        Пожалуйста, сделайте снимок экрана средствами вашей
                        операционной системы.
                    </strong>
                </p>
                <div class="buttons">
                    <button
                        class="mdl-button mdl-js-button mdl-js-ripple-effect colored bg-1"
                        @click="changePage('Result')"
                    >
                        Вернуться к результатам
                    </button>
                </div>
            </section>

            <section v-if="defaultStore.page === 'About'" id="pageAbout">
                <p>
                    <b>"Рандомайзер"</b> - это самый быстрый и удобный способ
                    выбрать победителя вашего розыгрыша ВКонтакте.<br />
                    Вам больше не нужно пользоваться несколькими программами,
                    генерировать случайные числа и самостоятельно отсчитывать
                    "восемнадцатого сверху на двадцатой странице".
                    <br /><br />
                    <span class="emotion">
                        Просто вставьте ссылку, укажите призы и получите
                        результат!
                    </span>
                </p>
            </section>

            <section v-if="defaultStore.page === 'Result'" id="pageResult">
                <p id="result-text">
                    Всего участников: {{ contest.repostsCount }}<br />
                    Количество победителей: {{ contest.winners.length }}<br />
                </p>
                <div id="result">
                    <div
                        v-for="winner in contest.winners"
                        :id="winner.id"
                        :key="'winner-' + winner.id"
                        class="winnerItem colored bg-1"
                    >
                        <a
                            :href="`https://vk.com/${
                                winner.domain || 'id' + winner.id
                            }`"
                            target="_blank"
                            class="avatar"
                        >
                            <div class="inline">
                                <img
                                    :src="winner.photo_50"
                                    :alt="winner.name"
                                    :title="winner.name"
                                />
                            </div>
                        </a>
                        <a
                            :href="`https://vk.com/${
                                winner.domain || 'id' + winner.id
                            }`"
                            target="_blank"
                            style="flex: 1; text-decoration: none"
                        >
                            <div class="inline name">
                                <div class="winnerName">
                                    {{ winner.first_name }}
                                    {{ winner.last_name }}
                                </div>
                                <div class="winnerPrize">
                                    Приз: {{ winner.prize }}
                                </div>
                            </div>
                        </a>
                        <div class="winnerBtns">
                            <button
                                :id="`btnRefresh-${winner.id}`"
                                class="mdl-button mdl-js-button mdl-button--icon replaceWinnerBtn"
                                @click="replaceWinner(winner.id)"
                            >
                                <i class="material-icons">replay</i>
                            </button>
                            <div
                                class="mdl-tooltip paper"
                                :for="`btnRefresh-${winner.id}`"
                            >
                                Найти другого
                            </div>
                        </div>
                    </div>
                </div>

                <div id="resultButtons" class="buttons">
                    <button
                        class="mdl-button mdl-js-button mdl-js-ripple-effect colored bg-1 buttonWithIcon"
                        style="margin-right: 10px"
                        @click="saveList()"
                    >
                        <i class="material-icons">&#xE2C4;</i>Сохранить как
                        текст...
                    </button>

                    <!-- TODO[2022-03-01]: Remove this button -->
                    <button
                        id="btnSaveScreen"
                        class="mdl-button mdl-js-button mdl-js-ripple-effect colored bg-1 buttonWithIcon"
                        @click="changePage('DeprecatedSaveScreen')"
                    >
                        <i class="material-icons">&#xE2C4;</i>Сохранить как
                        изображение...
                    </button>
                </div>
            </section>
        </div>
    </div>
    <FooterAd />
</template>

<script>
import * as Sentry from "@sentry/vue";
import { saveAs } from "file-saver";
import { mapStores, mapActions } from "pinia";
import { v4 as uuidv4 } from "uuid";
import shuffle from "lodash/shuffle";

import { fetchAPI, initVK, resizeWindowToFitAll } from "./utils/vk/index.js";
import { getQueryVariable, parseUrl } from "./utils/url-utils.js";
import { APP_URL, ERROR_MESSAGES } from "./constants.js";
import { useDefaultStore } from "./store.js";

export default {
    data: () => ({
        postLink: "",
        prizeTitle: "",
        prizeValue: "",
        prizes: [],
        prizeTitles: [],
        randoms: [],
        replaceWinners: [],
        replacePrizes: [],
        rejectedIds: [],
        lastRandom: undefined,
        counts: { EMPTY_RESPONSES: 0 },
        loadingText: "Выбираем победителей...",
        resultText: "",
        resultHtml: "",
        contest: {
            type: "repost",
            winners: [],
            needWinners: 0,
            owner: "",
            post: "",
            repostsCount: 0,
            checkSubscription: false,
        },
    }),
    computed: {
        ...mapStores(useDefaultStore),
    },
    watch: {
        postLink() {
            // part of the mdl-lite, refreshes elements
            setTimeout(() => window.componentHandler?.upgradeDom?.(), 0);
        },
    },
    created: async function () {
        const isDevelopment = import.meta.env.MODE === "development";
        if (isDevelopment) {
            this.prizes = [
                { id: uuidv4(), title: "Something", value: 1 },
                { id: uuidv4(), title: "Something else", value: 2 },
            ];
            this.postLink = "https://vk.com/wall-142399012_152";
        }
        await initVK();
    },
    methods: {
        ...mapActions(useDefaultStore, ["changePage", "setErrorMessage"]),
        async getWinners() {
            const url = this.postLink;
            const checkSub = this.contest.checkSubscription;
            const post = parseUrl(url);

            this.clearRejected();

            if (url && this.checkInput()) {
                if (!this.defaultStore.vkInitialized) {
                    await initVK();
                    if (this.defaultStore.page === "Error") return;
                }

                this.contest.type = getRadioValue("contestType");
                this.contest.winners = [];
                this.contest.needWinners = this.countPrizes();
                this.contest.owner = post.owner;
                this.contest.post = post.post_id;
                this.contest.checkSubscription = checkSub;

                this.loadingText = "Думаем...";

                this.changePage("Loading");

                await this.getLikesCount(this.contest.type);

                resizeWindowToFitAll();
            }
        },
        checkInput() {
            if (this.prizeTitle && this.prizeValue) {
                // but fields are filled and we can add one prize
                this.addPrize();
            }
            const postLink = this.postLink;
            const prizes = this.prizes;

            if (!postLink.toLowerCase().includes("wall")) {
                document
                    .querySelector("#postLink")
                    .setAttribute("invalid", "true");
                document
                    .querySelector("#postLink-container")
                    .classList.add("is-invalid");
                return false;
            }

            if (prizes.length === 0) {
                document
                    .querySelector("#prizeTitle-container")
                    .classList.add("is-invalid");
                document
                    .querySelector("#prizeValue-container")
                    .classList.add("is-invalid");
                return false;
            }

            return true;
        },
        addPrize() {
            const prizeTitle = document.querySelector("#prizeTitle");
            const prizeValue = document.querySelector("#prizeValue");

            if (!this.prizeTitle) {
                prizeTitle.setAttribute("invalid", "true");
                return;
            }

            if (!this.prizeValue) {
                prizeValue.setAttribute("invalid", "true");
                return;
            }

            this.prizes.push({
                id: uuidv4(),
                title: this.prizeTitle,
                value: Number.parseInt(this.prizeValue, 10),
            });

            this.prizeTitle = "";
            this.prizeValue = "";

            prizeTitle.removeAttribute("invalid");
            prizeValue.removeAttribute("invalid");
            document
                .querySelector("#prizeTitle-container")
                .classList.remove("is-invalid");
            document
                .querySelector("#prizeValue-container")
                .classList.remove("is-invalid");

            resizeWindowToFitAll();
        },
        deletePrize(id) {
            this.prizes = this.prizes.filter((prize) => prize.id !== id);
        },
        countPrizes() {
            let result = 0;
            this.prizeTitles = [];
            if (this.prizes.length === 0) return;

            for (let prize of this.prizes) {
                const prizeCount = Number.parseInt(prize.value, 10);
                for (let index = 0; index < prizeCount; ++index) {
                    this.prizeTitles.push(prize.title);
                    result += 1;
                }
            }

            return result;
        },
        showError(message) {
            this.setErrorMessage(message);
            this.changePage("Error");
        },
        async getLikesCount(filter = "likes") {
            try {
                const data = await fetchAPI("likes.getList", {
                    type: "post",
                    owner_id: this.contest.owner,
                    item_id: this.contest.post,
                    filter,
                    friends_only: "0",
                    extended: "0",
                    offset: "0",
                    count: "1",
                    skip_own: "1",
                });

                if (!data) {
                    this.showError(ERROR_MESSAGES.cantGetReposts);
                    return;
                }

                if (!data.response || !data.response.count) {
                    this.showError(ERROR_MESSAGES.cantGetReposts);
                    return;
                }

                this.contest.repostsCount = data.response.count;
                if (this.contest.needWinners > this.contest.repostsCount) {
                    this.showError(ERROR_MESSAGES.notEnoughParticipants);
                    return;
                }

                this.randoms = shuffle([
                    ...Array.from({ length: data.response.count }).keys(),
                ]);
                await (this.contest.type === "copies"
                    ? this.getRepost()
                    : this.getLike());
            } catch (error) {
                Sentry.captureException(error);
            }
        },
        getRandom() {
            this.lastRandom = this.randoms.shift();
            return this.lastRandom;
        },
        returnRandom() {
            this.randoms.push(this.lastRandom);
        },
        async getLike() {
            try {
                const data = await fetchAPI("likes.getList", {
                    type: "post",
                    owner_id: this.contest.owner,
                    item_id: this.contest.post,
                    filter: "likes",
                    friends_only: 0,
                    offset: this.getRandom(),
                    count: "1",
                });

                if (!data) {
                    this.showError(ERROR_MESSAGES.cantGetReposts);
                    return;
                }

                if (!data?.response?.items?.[0]) {
                    this.counts.EMPTY_RESPONSES += 1;
                    if (this.counts.EMPTY_RESPONSES >= 10) {
                        this.showError(ERROR_MESSAGES.cantGetReposts);
                        return;
                    }
                    this.returnRandom(data);
                    await this.nextStep();
                    return;
                }

                this.counts.EMPTY_RESPONSES = 0;

                await this.getUser(data.response.items[0]);
            } catch (error) {
                Sentry.captureException(error);
            }
        },
        async nextStep() {
            if (this.randoms.length <= 0) {
                this.showError(ERROR_MESSAGES.notEnoughParticipants);
                return;
            }

            if (this.contest.winners.length < this.contest.needWinners) {
                await (this.contest.type === "copies"
                    ? this.getRepost()
                    : this.getLike());
            } else {
                this.changePage("Result");
            }
        },
        async getUser(id) {
            if (Number.parseInt(id, 10) <= 0) {
                await this.nextStep();
                return;
            }

            try {
                const data = await fetchAPI("users.get", {
                    fields: "photo_50, domain",
                    user_ids: id,
                });

                if (!data) {
                    this.showError(ERROR_MESSAGES.cantGetReposts);
                    return;
                }

                if (!data?.response?.[0]) {
                    this.returnRandom(data);
                    await this.nextStep();
                    return;
                }

                if (this.contest.checkSubscription) {
                    await this.checkSubscription(data.response[0]);
                } else {
                    this.checkUser(data.response[0]);
                }
            } catch (error) {
                Sentry.captureException(error);
            }
        },
        async getRepost() {
            try {
                const data = await fetchAPI("wall.getReposts", {
                    type: "post",
                    owner_id: this.contest.owner,
                    post_id: this.contest.post,
                    offset: this.getRandom(),
                    count: "1",
                });

                if (!data) {
                    this.showError(ERROR_MESSAGES.cantGetReposts);
                    return;
                }

                if (
                    !data?.response?.items?.length ||
                    !data?.response?.items?.[0]?.to_id
                ) {
                    this.counts.EMPTY_RESPONSES += 1;
                    if (this.counts.EMPTY_RESPONSES >= 10) {
                        this.showError(ERROR_MESSAGES.cantGetReposts);
                        return;
                    }
                    this.returnRandom(data);
                    await this.nextStep();
                    return;
                }

                this.counts.EMPTY_RESPONSES = 0;

                await this.getUser(data.response.items[0].to_id);
            } catch (error) {
                Sentry.captureException(error);
            }
        },
        async checkSubscription(user) {
            if (!user) {
                await this.nextStep();
                return;
            }

            try {
                const data = await fetchAPI("groups.isMember", {
                    group_id: String(this.contest.owner).replace("-", ""),
                    user_id: user.id,
                    extended: "0",
                });
                if (!data) {
                    // TODO: show error?
                    await this.nextStep();
                    return;
                }

                if (!data.response) {
                    this.rejectedIds.push(user.id);
                    await this.nextStep();
                    return;
                }

                data.user = user;
                this.checkUser(data);
            } catch (error) {
                Sentry.captureException(error);
            }
        },
        checkUser(data) {
            const user = data.user || data;

            if (data?.response == "0") {
                this.rejectedIds.push(user.id);
            }

            if (user && "deactivated" in user) {
                this.rejectedIds.push(user.id);
            }

            if (
                user &&
                "id" in user &&
                !this.rejectedIds.includes(user.id) &&
                !data.error
            ) {
                this.addWinner(user);
            } else {
                this.nextStep();
            }
        },
        addWinner(winner) {
            winner.prize = this.prizeTitles[this.contest.winners.length];

            this.rejectedIds.push(winner.id);
            this.contest.winners.push(winner);
            this.loadingText = `${this.contest.winners.length} / ${this.contest.needWinners}`;

            this.nextStep();
        },
        async replaceWinner(id) {
            // part of the mdl-lite, refreshes elements
            window.componentHandler?.upgradeDom?.();

            const winnerIndex = this.contest.winners.findIndex(
                (winner) => winner.id === id
            );
            this.replaceWinners.push(id);
            this.replacePrizes.push(this.contest.winners[winnerIndex].prize);
            this.contest.winners.splice(winnerIndex, 1);

            await (this.contest.type === "copies"
                ? this.getRepost()
                : this.getLike());
        },
        clearRejected() {
            this.rejectedIds = [getQueryVariable("vk_user_id")];
        },
        saveList() {
            const textLines = ["Поздравляем победителей!", ""];
            for (const winner of this.contest.winners) {
                const { prize, domain, last_name, first_name } = winner;

                textLines.push(
                    `[${domain}|${first_name} ${last_name}] - ${prize}`
                );
            }

            textLines.push(
                "",
                `Победители выбраны с помощью приложения Рандомайзер (${APP_URL})`
            );

            const text = textLines.join("\n");
            const blob = new Blob([text], {
                type: "text/plain;charset=utf-8",
            });
            saveAs(blob, "winners.txt");
        },
    },
};

function getRadioValue(name) {
    const radios = document.getElementsByName(name);

    for (let index = 0, length = radios.length; index < length; index++) {
        if (radios[index].checked) {
            return radios[index].value;
        }
    }
}
</script>

<style lang="scss">
* {
    box-sizing: border-box;
}

body {
    font-family: "Roboto", "Noto", sans-serif;
    color: black;
}

#content-wrapper {
    display: flex;
    justify-content: center;
}

#content {
    width: 100%;
    border-radius: 0 0 4px 4px;
    background-color: white;
}

#label {
    position: relative;
    text-align: center;
    letter-spacing: 0.05em;
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    border-radius: 4px 4px 0 0;
    padding: 15px;
    margin: 0;
    box-sizing: border-box;
}

#content section {
    padding: 24px;
    overflow-y: auto;
    overflow-x: hidden;
}

#content section::-webkit-scrollbar {
    width: 6px;
}

#content section::-webkit-scrollbar-track {
    background-color: #eaeaea;
    border-left: 1px solid #ccc;
}

#content section::-webkit-scrollbar-thumb {
    background-color: #ccc;
}

#content section::-webkit-scrollbar-thumb:hover {
    background-color: rgb(0, 0, 0);
}

#result {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-height: 3750px;
    overflow-y: auto;
    line-height: 0;
}

.btn-add-prize {
    margin-top: 20px;
    color: #db4d54;
}

#loadingText {
    text-align: center;
    margin: 15px 0 0;
}

.paper {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);

    background-color: white;
    color: black;
}

.snow {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);
    background-color: #afcaec;
}

.flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

@for $i from 1 through 3 {
    .flex-#{$i} {
        flex: $i;
    }
}

.loadingSpinner {
    display: block;
    margin: 0 auto;
}

.flex-column > .mdl-textfield {
    width: 100%;
}

.mdl-textfield input {
    border-color: rgba(1, 0, 0, 0.5);
}

.mdl-textfield label {
    color: #757575;
}

.mdl-textfield--floating-label.is-dirty .mdl-textfield__label {
    color: hsla(0, 0%, 10%, 1);
}

.mdl-ripple {
    background-color: white;
}

.mdl-checkbox__box-outline {
    border-color: #db4d54;
}

.mdl-checkbox.is-checked .mdl-checkbox__box-outline {
    border-color: #db4d54;
}

.mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
    background: #db4d54;
}

.mdl-textfield__label:after {
    background-color: #db4d54;
}

.mdl-textfield--floating-label.is-focused .mdl-textfield__label {
    color: #db4d54;
}

.inline {
    display: inline-block;
}

.formTitle {
    color: #757575;
    font-family: "Roboto", "Noto", sans-serif;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
}

.contest-source {
    margin-bottom: 15px;
}

.contest-source label {
    margin-right: 15px;
    margin-bottom: 15px;
}

.winnerLoading {
    flex-basis: 40%;
    flex-shrink: 0;
    flex-grow: 3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: 10px;
}

.winnerItem {
    flex-basis: 40%;
    flex-shrink: 0;
    flex-grow: 3;
    display: flex;
    flex-direction: row;
    background-color: #db4d54;
    margin: 10px;
    box-shadow: 0 2px 2px 0 rgba(1, 1, 1, 0.14), 0 1px 5px 0 rgba(1, 1, 1, 0.12),
        0 3px 1px -2px rgba(1, 1, 1, 0.2);
    border-radius: 4px;
}

.winnerItem .avatar {
    flex-shrink: 0;
    border-radius: 3px 0 0 3px;
    overflow: hidden;
}

.winnerItem .name {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    color: white;
    padding: 11px 25px 0;
}

.winnerItem .name div {
    line-height: 14px;
}

.winnerItem a {
    display: block;
    color: white;
    background-color: rgba(1, 1, 1, 0.2);
}

.winnerBtns {
    background-color: rgba(1, 1, 1, 0.2);
}

.winnerName {
    font-weight: bold;
    margin-bottom: 4px;
}

.winnerPrize {
    font-size: 12px;
}

.buttons {
    display: flex;
    justify-content: center;
    padding-top: 15px;
}

.buttonWithIcon {
    display: flex;
    align-items: center;
}

#errorMessage {
    text-align: left;
}

.loading-image {
    text-align: center;
    margin-bottom: 15px;
}

.subheader {
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 10px;
}

.emotion {
    color: #db4d54;
    font-weight: bold;
}

.total {
    text-align: center;
    margin: 0;
    font-size: 25px;
}

paper-icon-button {
    width: 30px;
    height: 30px;
}

#pageMain paper-icon-button {
    color: #db4d54;
}

.mdl-radio__ripple-container .mdl-ripple,
.mdl-radio__inner-circle {
    background: #db4d54;
}

.mdl-radio.is-checked .mdl-radio__outer-circle {
    border: 2px solid #db4d54;
}

.replaceWinnerBtn {
    color: white;
}

.shaky {
    animation: shakeAndRotate 10s infinite;
}

.colored {
    background: #db4d54;
    color: white;
}

.bg-1 {
    background: url("./assets/images/backs/1.jpg") repeat-x;
}

#page-progress.preload-overlay {
    height: 100%;
    margin: -80px;
}

.preload-bar.preload-overlay {
    width: 240px;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.preload-progress.preload-overlay {
    width: 100%;
    height: 20px;
    background-image: -webkit-linear-gradient(
        315deg,
        #ea4537 25%,
        #fff 25%,
        #fff 50%,
        #ea4537 50%,
        #ea4537 75%,
        #fff 75%,
        #fff
    );
    background-image: linear-gradient(
        135deg,
        #ea4537 25%,
        #fff 25%,
        #fff 50%,
        #ea4537 50%,
        #ea4537 75%,
        #fff 75%,
        #fff
    );
    background-size: 30px 30px;
    border-radius: inherit;
    animation: animate-stripes 5s linear infinite;
}

preload-overlay {
    background: #3ec4f0;
    z-index: 1200;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

img.preload-overlay {
    width: 340px;
    height: 340px;
}

preload-overlay {
    visibility: hidden;
}

preload-overlay.fading {
    transition: opacity 600ms ease-in-out;
    opacity: 0;
    visibility: visible;
}

preload-overlay[active] {
    visibility: visible;
}

.mdl-tooltip {
    will-change: unset;
}

.mdl-snackbar {
    bottom: 8px;
    width: 784px;
    max-width: 784px;
}

/* ANIMATIONS */

@keyframes animate-stripes {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 60px 0;
    }
}

@keyframes shakeAndRotate {
    0%,
    35%,
    42%,
    87%,
    94%,
    100% {
        transform: rotateZ(0);
    }
    39%,
    41%,
    91%,
    93% {
        transform: rotateZ(-30deg);
    }
    40%,
    92% {
        transform: rotateZ(30deg);
    }
}
</style>
