import { createApp } from "vue";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import bridge from "@vkontakte/vk-bridge";

import App from "./App.vue";
import { getQueryVariable } from "./utils/url-utils.js";

const app = createApp(App);
app.use(createPinia());
app.mount("#app");

Sentry.init({
    app,
    dsn: "https://a470f84eeaa2470c8b4a4677ec6af3c9@sentry.io/1390522",
    release: __APP_VERSION__,
    integrations: [new Integrations.BrowserTracing({})],
    normalizeDepth: 11,
    tracesSampleRate: 0.1,
    initialScope: {
        tags: {
            vk_is_app_user: getQueryVariable("vk_is_app_user"),
            platform: getQueryVariable("vk_platform"),
        },
        user: { id: getQueryVariable("vk_user_id") },
    },
    beforeSend: (event, hint) => {
        if (!import.meta.env.PROD) {
            console.error(
                hint.originalException || hint.syntheticException || event
            );
            return; // this drops the event and nothing will be sent to sentry
        }
        return event;
    },
});

(async () => {
    await bridge.send("VKWebAppInit", {});
})();
