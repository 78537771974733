<script setup>
import IconButton from "./IconButton.vue";
</script>

<template>
    <div>
        <p>Development Mode</p>
        <IconButton
            tooltip="Главная страница"
            icon="home"
            @click="changePage('Main')"
        ></IconButton>
        <IconButton
            tooltip="О приложении"
            icon="info"
            @click="changePage('About')"
        ></IconButton>
        <IconButton
            tooltip="Загрузка"
            icon="hourglass_top"
            @click="changePage('Loading')"
        ></IconButton>
        <IconButton
            tooltip="Ошибка"
            icon="error"
            @click="changePage('Error')"
        ></IconButton>
        <IconButton
            tooltip="Результаты"
            icon="emoji_events"
            @click="changePage('Result')"
        ></IconButton>
    </div>
</template>

<script>
import { mapActions } from "pinia";
import { useDefaultStore } from "../store.js";

export default {
    name: "DevelopmentModePanel",
    data: () => ({}),
    methods: {
        ...mapActions(useDefaultStore, ["changePage"]),
    },
};
</script>

<style scoped></style>
