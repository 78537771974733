<template>
    <button
        :id="id"
        class="mdl-button mdl-js-button mdl-button--icon"
        @click="$emit('click', $event)"
    >
        <i class="material-icons">{{ icon }}</i>
    </button>
    <div v-if="tooltip" class="mdl-tooltip paper" :for="id">{{ tooltip }}</div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
    props: {
        icon: {
            type: String,
            required: true,
        },
        tooltip: {
            type: String,
        },
    },
    emits: ["click"],
    data: () => ({ id: uuidv4() }),
};
</script>
