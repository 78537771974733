<template>
    <div id="label" class="colored bg-1 flex-row">
        <IconButton
            v-if="defaultStore.page !== 'Main'"
            tooltip="Вернуться назад"
            icon="home"
            @click="changePage('Main')"
        ></IconButton>

        <span id="title" class="flex-1"> {{ pageTitle }} </span>

        <IconButton
            v-if="defaultStore.page === 'Result'"
            tooltip="Повторить розыгрыш"
            icon="refresh"
            @click="getWinners()"
        ></IconButton>

        <IconButton
            tooltip="О приложении"
            icon="info"
            @click="changePage('About')"
        ></IconButton>
    </div>
</template>
<script>
import { mapActions, mapStores } from "pinia";
import IconButton from "./IconButton.vue";
import { useDefaultStore } from "../store.js";

export default {
    name: "AppHeader",
    components: { IconButton },
    props: {
        page: { type: String },
        getWinners: { type: Function },
    },
    emits: ["getWinners"],
    methods: {
        ...mapActions(useDefaultStore, ["changePage"]),
    },
    computed: {
        pageTitle() {
            switch (this.page) {
                case "Result":
                    return "Поздравляем победителей!";
                case "Loading":
                    return "Выбираем победителей...";
                case "Feedback":
                    return "Оставить отзыв";
                case "FeedbackSent":
                    return "Отзыв отправлен";
                case "About":
                    return "О приложении";
                case "Settings":
                    return "Параметры";
                case "Error":
                    return '"Хьюстон, у нас проблема"';
                case "DeprecatedSaveScreen": // TODO[2022-03-01]: Remove this page
                    return "Функция больше недоступна";
                default:
                    return "Выбрать победителя розыгрыша";
            }
        },
        ...mapStores(useDefaultStore),
    },
};
</script>

<style lang="scss" scoped></style>
